import React from 'react';
import p4_left from '../images/page4_left.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import abc from '../images/abcmarking.PNG';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
    left: {
        minHeight: '92.2vh',
        '& img': {
            objectFit: 'cover',
            width: "100%",
            height: '100%',
            maxHeight: '100%'
        }
    },
    right: {
        minHeight: '92.2vh',
        overflow: 'overlay',
        backgroundColor: '#FFF'
    },
    containerRight: {
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100%',
        justifyContent: 'flex-end',
        padding: theme.spacing(3),
        '& p': {
            paddingBottom: theme.spacing(1)
        }
    },
    bottomItem: {
        alignSelf: 'center',
        paddingTop: theme.spacing(3)
    }
}));

export default function Energy() {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.left}>
                    <img src={p4_left}/>
                </Grid>
                <Grid item xs={12} md={6} className={classes.right}>
                    <Grid container className={classes.containerRight}>
                        <Grid item>
                            <Typography variant="h5" style={{fontWeight: 'bold'}}>
                                Energimærkning
                            </Typography>
                            <Typography variant="h5" style={{fontWeight: 'bold', paddingBottom: 30}}>
                                - hold styr på energien i din bolig
                            </Typography>
                            <Typography variant="body2" component="p">
                                Når din bolig skal sælges, er det lovpligtigt at få lavet et energimærke, 
                                så den energimæssige klassificering fremgår i salgsannoncen. 
                                Energimærket gælder i op til 10 år.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Energimærkningen udarbejdes af vores godkendte energikonsulenter, 
                                der foretager en grundig gennemgang af din ejendom. 
                                Rapporten indeholder også forslag til energimæssige forbedringer, 
                                der kan være med til at nedsætte ejendommens energiforbrug.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Ved enfamiliehuse, række eller dobbelthuse der max er 25 år gamle, 
                                kan der også udføres energimærkning uden bygningsgennemgang. 
                                Det betyder at energikonsulenten ikke gennemgår boligen fysisk. 
                                Du skal dog være opmærksom på, at eventuelle ændringer og forbedringer ikke vil fremgå af energimærkningen.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Ejendomme med flere lejeligheder /udlejningsejendomme og 
                                erhvervsbygninger skal energimærkes, 
                                så udlejer/ejer altid kan fremvise et gyldigt energimærke til sine lejere. 
                                Erhvervsbygninger der skal handles eller overdrages, skal ligeledes energimærkes. 
                            </Typography>
                        </Grid>
                        <Grid item className={classes.bottomItem}>
                            <img src={abc} style={{maxHeight: '8vh'}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}