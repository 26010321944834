import React, {Component} from 'react';
import Sidebar from './components/Sidebar'
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
    }

    render () {
       return (
           <Sidebar/>
        );
    }
}

export default App;
