import React from 'react';
import p7_right from '../images/page7_right.jpg';
import { makeStyles } from '@material-ui/core/styles';
import papertool from '../images/papertool.PNG';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    right: {
        minHeight: '92.2vh',
        '& img': {
            objectFit: 'cover',
            width: "100%",
            height: '100%',
            maxHeight: '100%'
        }
    },
    left: {
        minHeight: '92.2vh',
        height: 'fit-content',
        overflow: 'overlay',
        backgroundColor: '#FFF',
        flexDirection: 'column',
        justifyContent: 'space-around',
        display: 'flex',
        padding: theme.spacing(3)
    },
    leftText: {
        '& p': {
            paddingBottom: theme.spacing(1),
        }
    }
}));

export default function Maintenance() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.left}>
                    <Grid container>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant="h5" style={{fontWeight: 'bold', paddingBottom: 25}}>
                                    Vedligeholdesplaner - hold din ejendom vedlige
                                </Typography>
                            </Grid>
                            <Grid item xs={2} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <img src={papertool} style={{maxHeight: '8vh'}}/>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.leftText}>
                            <Typography variant="body2" component="p">
                                En vedligeholdelsesplan er en rapport der giver overblik over din ejendoms tilstand og 
                                vedligeholdelsesopgaver. I en vedligeholdelsesplan får du lagt et realistisk og prioriteret 
                                budget for ejendommens fremtidige drift- og vedligeholdelsesopgaver for de næste 10 år.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Vedligeholdelsesplaner udføres på baggrund af en byggeteknisk gennemgang af din ejendom. 
                                Efter gennemgangen kan vi planlægge dine vedligeholdsopgaver og opstille hvilke forbedringer og 
                                renoveringer, der skal udføres. Vi opdeler opgaverne i bygningsdele 
                                (fx vinduer, facader, tag etc.), med uddybende beskrivelser af vedligeholdets omfang 
                                samt med overslag på forbedringernes omkostninger m.m. Heraf prioriterer vi også hvornår 
                                opgaverne bør udføres, samt hvor meget det ca. koster de næste 10 år.
                            </Typography>
                            <Typography variant="body2" component="p">
                                10 års budgettet er dit vigtigste redskab i vedligeholdelsesarbejdet. 
                                Det er som udgangspunkt det, som du skal forholde dig til, når du får leveret en vedligeholdelsesplan. 
                                Vi anbefaler, at budgettet bliver opdateret løbende og vedligeholdelsesplanen bliver brugt aktivt. 
                                For med et opdateret budget ved du altid, hvor meget der skal spares op til de enkelte arbejder. 
                            </Typography>
                            <Typography variant="body2" component="p">
                                Drift og vedligehold er vigtig for din ejendom og kan forlænge levetiden på de fleste bygningsdele. 
                                En god vedligeholdelse er også bedre for din økonomi. Med en vedligeholdelsesplan får du et 
                                godt overblik over ejendommens tilstand, og du kan samtidig få et budget over de næste 10 års 
                                vedligeholdelse af ejendommen.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Vi udfører vedligeholdelsesplaner på alle typer ejendomme og dækker hele landet. 
                                Ring og hør nærmere og få et tilbud på en vedligeholdelsesplan der passer til din ejendom.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Ankersen ApS samarbejder med MinEjendom og vedligeholdelsesplaner udarbejdes i deres platform.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.right}>
                    <img src={p7_right}/>
                </Grid>
            </Grid>
        </div>
    )
}