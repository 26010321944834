import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ulla_picture from '../images/ulla.jpg';
import lars_picture from '../images/lars.png';
import torben_picture from '../images/torben.jpg';
import manIcon from '../images/manIcon.PNG';
import LocationOn from '@material-ui/icons/LocationOn';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
    left: {
        minHeight: '92.2vh',
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: 'white',
        paddingTop: 150,
        '& h4': {
            marginBottom: 20,
            color: '#7DA1A1',
            fontWeight: 'bold'
        },
        '& p': {
            color: '#7DA1A1',
        }
    },
    leftBodyText: {
        color: '#7DA1A1'
    },
    right: {
        minHeight: '92.2vh',
        overflow: 'overlay',
        padding: theme.spacing(3),
        backgroundColor: '#7DA1A1'
    },
    cardItem: {
        display: 'flex',
        justifyContent: 'center'
    },
    card: {
        backgroundColor: '#7DA1A1',
        boxShadow: '0px 0px 0px 0px',
        overflow: 'visible'
    },
    cardMedia: {
        minHeight: '30vh',
        maxWidth: '20vh',
        margin: 'auto'
    },
    cardContent: {
        paddingTop: 10,
        textAlign: 'center',
        color: '#FFF',
    },
    gridWithElements: {
        height: '100%',
        '& img': {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            height: '28vh'
        },
    },
    imageElementsOfGrid: {
        textAlign: 'center',
        color: '#FFF'
    },
}));

const cards = [
    {
        name: 'Lars Ankersen',
        title: 'Bygningsingeniør',
        tlf: '22 24 24 20',
        mail: 'lars@ankersenaps.dk',
        image: lars_picture,
        id: 0
    },
    {
        name: 'Ulla Rahbek',
        title: 'Planlægger',
        tlf: '60 19 27 47',
        mail: 'ulla@ankersenaps.dk',
        image: ulla_picture,
        id: 2
    },
    {
        name: 'Torben Bo Hansen',
        title: 'Bygningskonstruktør',
        tlf: '51 34 68 05',
        mail: 'torben@ankersenaps.dk',
        image: torben_picture,
        id: 1
    },

];

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <CssBaseline />
        <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
                <Container className={classes.left}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    Hvem er vi?
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                  Ankersen ApS er en mindre konsulentvirksomhed med ingeniører, bygningskonstruktører, byggesagkyndige, planlæggere og energikonsulenter.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    Vores hovedopgaver består i at udføre tilstandsrapporter og energimærker, men vi tilbyder også andre typer af opgaver, så som fx vedligeholdelsesplaner, byggetilsyn, varmetabsrammer, trykprøvning mv. 
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    Vi er et professionelt team, der behandler din ejendom med respekt og diskretion.
                                </Typography> 
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component="p">
                                    Vi giver gerne et uforpligtende tilbud, så giv og endelig et kald eller send en mail.
                                </Typography>
                            </Grid>
                        </Grid>
                </Container>
            </Grid>
            <Grid item xs={12} md={6}>
                <Container className={classes.right}>
                        <Grid container
                            alignContent='center'
                            alignItems='center'
                            justify='space-between' 
                            direction='column' 
                            className={classes.gridWithElements}>
                            <Grid item className={classes.imageElementsOfGrid}>
                                <LocationOn/>
                                <Typography variant="body2">
                                    Ankersen ApS
                                </Typography>
                                <Typography variant="body2">
                                    Danmarksgade 28    
                                </Typography>
                                <Typography variant="body2">
                                    6700 Esbjerg
                                </Typography>   
                                <img src={manIcon}/>
                            </Grid>
                                <Grid container alignItems="center" justify="space-around">
                                    {cards.map(card => (
                                    <Grid item key={card.id} xs={6} lg={4} className={classes.cardItem}>
                                        <Card className={classes.card}>
                                            <CardMedia
                                                className={classes.cardMedia}
                                                image={card.image}
                                            />
                                            <CardContent className={classes.cardContent}>
                                                <Typography variant="body2">
                                                    {card.name}
                                                </Typography>
                                                <Typography gutterBottom variant="body2">
                                                    {card.title}
                                                </Typography>
                                                <Typography gutterBottom variant="h3"/>
                                                <Typography variant="body2">
                                                    {card.tlf}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {card.mail}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                </Container>
            </Grid>
        </Grid>
    </div>
  );
}