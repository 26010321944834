import React from 'react';
import p5_right from '../images/page5_right.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    right: {
        minHeight: '92.2vh',
        '& img': {
            objectFit: 'cover',
            width: "100%",
            height: '100%',
            maxHeight: '100%'
        }
    },
    left: {
        minHeight: '92.2vh',
        height: 'fit-content',
        overflow: 'overlay',
        backgroundColor: '#FFF',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        display: 'flex',
    },
    leftText: {
        padding: theme.spacing(3),
    }
}));

export default function Newbuild() {
    const classes = useStyles();

    return ( 
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.left}>
                    <Grid container>
                        <Grid item className={classes.leftText}>
                            <Typography variant="h5" style={{fontWeight: 'bold', paddingBottom: 25}}>
                                Trykprøvning og energimærkning af store og små nye bygninger                            </Typography>
                            <Typography variant="body2" component="p" style={{fontWeight: 'bold'}}>
                                Trykprøvning
                            </Typography>
                            <Typography variant="body2" component="p" style={{paddingBottom: 8}}>
                                Formålet med en trykprøvning er at påvise, hvor tæt en bygning er. 
                                I bygningsreglementet fremgår specifikke krav til, hvor tæt en ny bygning skal være. 
                                Der er primært 2 årsager til, at vi bygger tætte bygninger.
                            </Typography>
                            <Typography variant="body2" component="p" style={{fontWeight: 'bold'}}>
                                1. Energiforbruget
                            </Typography>
                            <Typography variant="body2" component="p">
                                I en utæt bygning slipper den varme luft nemmere ud, 
                                derfor bygger vi i dag tætte huse for at beholde den varme luft inde.
                            </Typography>
                            <Typography variant="body2" component="p" style={{fontWeight: 'bold'}}>
                                2. Fugt
                            </Typography>
                            <Typography variant="body2" component="p" style={{paddingBottom: 8}}>
                                Bygninger – især boliger – er under en konstant fugtbelastning. 
                                Ved at have en tæt bygning, er man sikker på, 
                                at fugten ikke utilsigtet slipper igennem bygningens konstruktioner og laver fugtskader, 
                                som på sigt kan udvikle sig til skimmel, råd og svamp, der skader bygningsdele og skaber 
                                dårlig og usundt indeklima.
                            </Typography>
                            <Typography variant="body2" component="p" style={{fontWeight: 'bold'}}>
                                Energimærkning
                            </Typography>
                            <Typography variant="body2" component="p" style={{paddingBottom: 8}}>
                                En nybygget bolig skal altid være energimærket. 
                                Energimærkningen viser husets energimæssige tilstand og skal bekræfte, 
                                at huset lever op til energikravene i bygningsreglementet. 
                                Når et nybygget hus er færdigbygget, skal det færdigmeldes til kommunen, før du må flytte ind i det.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Ankersen ApS udfører energimærkning samt trykprøvning med den 
                                såkaldte ”Blover Door” test efter normen DS/EN 9972, 
                                bygningernes tæthed/termiske ydeevne. 
                                Udstyret kan klare opgaver fra små lejligheder til institutioner og fabrikshaller. 
                                Energimærke og trykprøvning danner grundlag for attesten til kommunen og bygherren.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.right}>
                    <img src={p5_right}/>
                </Grid>
            </Grid>
        </div>
    )
}