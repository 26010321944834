import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import p2_left from '../images/page2_left.jpg'
import cart from '../images/cartp2.PNG';
import { Typography, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
    left: {
        minHeight: '92.2vh',
        '& img': {
            objectFit: 'cover',
            width: "100%",
            height: '100%',
            maxHeight: '100%'
        }
    },
    right: {
        minHeight: '92.2vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: '#FFF',
    },
    topRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        color: '#7DA1A1',
        padding: theme.spacing(3),
    },
    bottomRight: {
        color: '#FFF',
        backgroundColor: '#99BDBD',
        padding: theme.spacing(3),
    }
}));

export default function Reports() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.left}>
                    <img src={p2_left}/>
                </Grid>
                <Grid item xs={12} md={6} className={classes.right}>
                    <Grid container className={classes.rightContainer}>
                        <Grid item className={classes.topRight}>
                            <Grid container direction="row" style={{paddingBottom: 35}}>
                                <Grid item xs={8}>
                                    <Typography variant="h5" style={{fontWeight: 'bold'}}>
                                        Har du fundet en køber til din bolig?
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                                    <img src={cart} style={{maxWidth: '8vh', maxHeight: '8vh'}}/> 
                                </Grid>
                            </Grid>
                            <Typography variant="body2" component="p">
                                Med en tilstandsrapport får du et overblik over, hvordan tilstanden er på dit hus.
                                Når du sælger dit hus, skal der foreligge en tilstandsrapport, elinstallationsrapport
                                og et tilbud på en ejerskifteforsikring. Har du ikke disse, står du med ansvaret for fejl og mangler i op til 10 år efter salget. 
                                Rapporterne og tilbuddet skal foreligge inden der underskrives en købsaftale.
                            </Typography>
                        </Grid>
                        <Grid item className={classes.bottomRight}>
                            <Typography variant="body2" component="p" style={{fontWeight: 'bold', paddingBottom: 12}}>
                               Tilstandsrapportens skala har seks trin
                            </Typography>
                            <Grid container direction='column' style={{display: 'block'}}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                IB
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="body2">
                                                Ingen bemærkninger
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                K0
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="body2">
                                                Rent kosmetiske skader
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                K1
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="body2">
                                                Mindre alvorlige skader, som  ikke har indflydelse på bygningsdelens funktion
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                K2
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="body2">
                                                Alvorlige skader, hvor bygningsdelens funktion vil svigte  
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                K3
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="body2">
                                                Kritiske skader, hvor bygningsdelens funktion vil svigte og hvor det kan medføre skader på andre bygningsdele
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                UN
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="body2">
                                                Undersøges nærmere, hvilket betyder at den byggesagkyndige ikke kan overskue konsekvenserne af en fejl eller mangel
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
          </Grid>
      </div>  
    );
}