import React, { Component, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import logo from '../images/logo.png';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


import Home from '../components/Home';
import Reports from './Reports';
import Consultancy from './Consultancy';
import Energy from './Energy';
import Newbuild from './NewBuildings';
import Insurance from './Insurance';
import Maintenance from './Maintenance';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
      },
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appBar: {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          backgroundColor: "#99BDBD",
        },
        [theme.breakpoints.up('xs')]: {
          backgroundColor: '#99BDBD'
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
      },
}));

export default function ResponsiveDrawer(props) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        scrollTo(index);
    };

    const scrollToTop = () => {
      scroll.scrollToTop();
    };

    const homeRef = useRef();
    const reportsRef = useRef();
    const consultancyRef = useRef();
    const energyRef = useRef();
    const insuranceRef = useRef();
    const maintenanceRef = useRef();
    const newbuildingsRef = useRef();
    const toolbarRef = useRef();

    const scrollTo = (index) => {
        let off_set = null;
        switch(index) {
            case 0:
                off_set = homeRef.current.offsetTop;
                break;
            case 1:
                off_set = reportsRef.current.offsetTop;
                break;
            case 2:
                off_set = consultancyRef.current.offsetTop;
                break;
            case 3:
                off_set = energyRef.current.offsetTop;
                break;
            case 4:
                off_set = newbuildingsRef.current.offsetTop;
                break;
            case 5:
                off_set = insuranceRef.current.offsetTop;
                break;
            case 6:
                off_set = maintenanceRef.current.offsetTop;
                break;
            default:
                off_set = homeRef.current.offsetTop;
                break;
        }
        let off_set_toolbar = toolbarRef.current.offsetHeight;
        let resultOffset = off_set - off_set_toolbar;
        scroll.scrollTo(resultOffset, {
          duration: 900,
          delay: 0,
          smooth: 'easeInOutQuart',
      })
    };

    const drawer = (
        <div>
          <div className={classes.toolbar} />
          <Divider />
          <List>
                    {['Hjem', 'Tilstandsrapport', 'Rådgivning ved nybyg', 'Energimærke', 'Trykprøvning', 'Forsikringsskader', 'Vedligeholdelsesplan'].map((text, index) => (
                        <ListItem button key={text}
                                  selected={ selectedIndex === index }
                                  onClick={event => handleListItemClick(event, index)}
                        >
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
        </div>
      );

    return ( 
        <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} style={{maxWidth: '20%'}}/>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
            <div className={classes.toolbar} ref={toolbarRef}/>
            <div ref={homeRef}>
              <Home/>
            </div>
            <div ref={reportsRef}>
              <Reports/>
            </div>
            <div ref={consultancyRef}>
              <Consultancy/>
            </div>
            <div ref={energyRef}>
              <Energy/>
            </div>
            <div ref={newbuildingsRef}>
              <Newbuild/>
            </div>
            <div ref={insuranceRef}>
              <Insurance/>
            </div>
            <div ref={maintenanceRef}>
              <Maintenance/>
            </div>
        </main>
    </div>
    );
}