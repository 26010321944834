import React from 'react';
import p3_left from '../images/page3_left.PNG';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import house from '../images/house.PNG';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Arial, Helvetica, sans-serif',
        color: '#7DA1A1',
    },
    left: {
        backgroundImage: 'url(' + p3_left + ')',
        backgroundPosition: 'bottom',
        minHeight: '92.2vh',
        color: '#000',
        padding: theme.spacing(3),
        paddingTop: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& p': {
            paddingBottom: theme.spacing(2)
        }
    },
    right: {
        minHeight: '92.2vh',
        overflow: 'overlay',
        backgroundColor: '#FFF',
        padding: theme.spacing(3)
    },
    itemsRight: {
        paddingBottom: theme.spacing(1),
        '& p': {
            paddingTop: theme.spacing(1),
        }
    },
}));

export default function Consultancy() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={6} className={classes.left}>
                    <Grid container direction='row' justify="space-between">
                        <Grid item xs={10}>
                            <Typography variant="h4" style={{fontWeight: 'bold', paddingBottom: 18}}>
                                Rådgivning ved køb af bolig
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>

                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" component="p">
                            En byggeteknisk køberrådgivning er en gennemgang af boligen samme med dig, 
                            hvor vi sammen ser på boligens generelle stand, tilstandsrapport, energimærke, 
                            el-rapport samt rådgivning omkring vedligeholdelser
                        </Typography>
                        <Typography variant="body2" component="p">
                            I forbindelse med den byggetekniske køberrådgivning vil Ankersen ApS estimere priser for 
                            de eventuelle udbedringer af fejl i tilstandsrapport og elrapport. 
                            Ved en byggeteknisk køberrådgivning fra Ankersen ApS deltager en byggesagkyndig og eventuelt en elektriker
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.right}>
                    <Grid container direction="column" justify='space-around' style={{ height: '100%' }}>
                        <Grid item className={classes.itemsRight}>
                            <Grid container direction="row">
                                <Grid item xs={8}>
                                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '1.5em', paddingBottom: 18 }}>
                                        Rådgivning i forbindelse med nybyggeri
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }} >
                                    <img src={house} style={{ maxHeight: '9vh', maxWidth: '8vh' }} />
                                </Grid>
                            </Grid>
                            <Typography variant="body2" component="p">
                                Som kommende boligejer vil du typisk få brug for professionel rådgivning.
                                Rådgivning i forbindelse med et nybyggeri består af en gennemgang af
                                udbudsmaterialet inklusiv eventuelle kommentarer til entreprenører/typehusfirmaer.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Under opførsel af boligen vil Ankersen ApS besigtige byggeriet og foretage
                                stikprøvekontroller af alle de kritiske bygningsdele og konstruktioner.
                                Byggetilsynet strækker sig lige fra støbning af fundamenter og til
                                afleveringsforretning den dag, du overtager det nøglefærdige hus.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Ved hvert tilsyn vil der blive udarbejdet et notat, som beskriver de forhold,
                                der er konstateret ved tilsynet. Er der fejl og mangler ved tilsynet,
                                tager vi dialogen direkte med entreprenør. Ved næste tilsyn vil vi følge op på
                                eventuelle fejl og mangler.
                            </Typography>
                        </Grid>
                        <Grid item className={classes.itemsRight3}>                      
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                        Hvad er inkluderet i et byggetilsyn
                            </Typography>
                            <Grid container direction='column' style={{display: 'block'}}>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" component="p">
                                                -
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="body2" component="p">
                                                Fundament i forbindelse med støbning af fundamenter og armering
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" component="p">
                                                -
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="body2" component="p">
                                                Terrændæk inden støbning af gulve for kontrol, af armering af installationer
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" component="p">
                                                -
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="body2" component="p">
                                                Tilsyn med tagkonstruktioner og bagmure for kontrol og forankring, undertag ect.                                           
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" component="p">
                                                -
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="body2" component="p">
                                                Kontrol af fugtsikring af murearbejde ved bl.a. fundamenter
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" component="p">
                                                -
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="body2" component="p">
                                                Tilsyn med dampspærre, vådrumssikring og fald på gulve
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" component="p">
                                                -
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="body2" component="p">
                                                En gennemgang af boligen ca. 14 dage inden endelig overdragelse
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <Typography variant="body2" component="p">
                                                -
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant="body2" component="p">
                                                Deltagelse i endelig afleveringsforretning med entreprenør
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}