import React from 'react';
import p6_left from '../images/page6_left.PNG'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import house from '../images/house2.PNG';
import houseCard from '../images/housecard.PNG';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
    left: {
        backgroundImage: 'url(' + p6_left + ')',
        backgroundPosition: 'bottom',
        minHeight: '92.2vh',
        color: '#FFF',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        '& p': {
            paddingBottom: theme.spacing(2)
        }
    },
    right: {
        minHeight: '92.2vh',
        overflow: 'overlay',
        backgroundColor: '#FFF',
        color: '#7DA1A1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    rightText: {
        padding: theme.spacing(3),
        '& p': {
            paddingBottom: theme.spacing(3)
        }
    },
    topItemRight: {
        paddingTop: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    bottomLeftImg: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
    },
    topLeftText: {
        maxHeight: '100%',
        paddingBottom: theme.spacing(7),
    }
}));

export default function Insurance() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.left}>
                    <Grid container>
                        <Grid item className={classes.topLeftText}>
                            <Typography variant="h4" style={{fontWeight: 'bold', paddingBottom: 18}}>
                                Varmetabsrammer
                            </Typography>
                            <Typography variant="body2" component="p">
                                I forbindelse med et nybyggeri, hvor der skal søges byggetilladelse, 
                                skal der jf. bygningsreglement 2018 foreligge i en energiramme på bygningen. 
                                Denne energiramme skal indeholde: Arealer af hele klimaskærmen, u-værdiberegning af 
                                alle konstruktionsdele, ventilation, bygnings forbrug, forsyningskilde osv.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Alle disse beregninger bliver lagt ind i en energiramme, hvor husets årlige 
                                forbrug pr. m2 beregnes. Godkendes bygningen af beregningsprogrammet kan 
                                energirammen sendes ind til kommunen og en byggetilladelse kan blive udstedt. 
                                Energirammeberegning udføres som rapport , der udsendes pr. mail.
                            </Typography>
                            <Typography variant="body2" style={{fontWeight: 'bold', paddingBottom: 0}}>
                               For at udføre varmetabsramme skal vi bruge:
                            </Typography>
                            <Typography variant="body2" component="p">
                                Et sæt bygningstegninger, samt oplysninger om ventilation og opvarmningsform.
                            </Typography>
                        </Grid>
                        <Grid item className={classes.bottomLeftImg}>
                            <img src={houseCard} style={{maxHeight: '17vh'}}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.right}>
                    <Grid container>
                        <Grid item className={classes.topItemRight}>
                            <img src={house} style={{maxHeight: '12vh'}}/>
                        </Grid>
                        <Grid item className={classes.rightText}>
                            <Typography variant="h5" style={{fontWeight: 'bold', paddingBottom: 18}}>
                                Når uheldet er ude - hjælp ved forsikringsskader
                            </Typography>
                            <Typography variant="body2" component="p">
                                Uheldet er ude – huset er blevet skadet af vand, storm eller andre ulykker, 
                                skader skal anmeldes til forsikringsselskabet. Vi hjælper med overblik, 
                                kender de begreber og fagtermer som forsikringsfolk bruger, 
                                og vi kan også være til stede, når du får dit hjem besigtiget.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Ved at have os med på sidelinjen sikrer du, at du får det hele med. 
                                Du får dermed den erstatning, som du har krav på. 
                                Vi kender de fleste typer skader, og samarbejdet med dit forsikringsselskab glider 
                                meget lettere, hvis du har assistance fra en professionel og personlig rådgiver. 
                                Vores hjælp skaber dig overblik og tryghed, hvis der opstår en ærgerlig og 
                                besværlig situation. Ankersen ApS udarbejder skaderapporter for dig der giver 
                                forsikringsselskabet et rigtigt billede af skaden, årsag og omfang.
                            </Typography>
                            <Typography variant="body2" component="p">
                                Det er dig som skadelidt, der skal bevise overfor forsikringen, at du har en skade, 
                                som skal dækkes. Vores rapport er et vigtigt dokument i den proces og det er bedst, 
                                hvis vi er med helt fra starten. Hurtig kontakt til os, når skaden er sket kan 
                                spare dig mange ærgrelser i sagsbehandlingen.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}